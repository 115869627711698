/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import { ThemeProvider } from '@webteam/ui-contexts';

import { ThemeSwitcherProvider } from 'src/components/theme-switcher';

// You can delete this file if you're not using it

export const onClientEntry = () => {
  require('@webteam/layout/lib/webpack-autoload');
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  scrollToAnchor(location, 0);

  return false;
};
/**
 * Native scrollTo with callback
 * @param offset - offset to scroll to
 * @param callback - callback function
 */
function scrollTo(offset, callback) {
  const fixedOffset = Math.min(offset, getBottomYOffset()).toFixed();

  const onScroll = () => {
    if (window.pageYOffset.toFixed() === fixedOffset) {
      window.removeEventListener('scroll', onScroll);
      callback();
    }
  };

  window.addEventListener('scroll', onScroll);
  onScroll();
  window.scrollTo({
    top: offset,
    behavior: 'smooth',
  });
}

function getBottomYOffset() {
  return (
    Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    ) - window.innerHeight
  );
}

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */
function scrollToAnchor(location, mainNavHeight = 0) {
  // Check for location so build does not fail
  if (location && location.hash) {
    const item = document.querySelector(`${location.hash}`).offsetTop;
    window.isScrolling = true;
    scrollTo(item - mainNavHeight, () =>
      setTimeout(() => (window.isScrolling = false), 10)
    );
  }

  return true;
}

export const wrapPageElement = ({ element }) => (
  <ThemeSwitcherProvider>
    {(currentTheme) => (
      <ThemeProvider theme={currentTheme}>{element}</ThemeProvider>
    )}
  </ThemeSwitcherProvider>
);
