import React, { useContext, useEffect, useState } from 'react';

let defaultTheme = 'light';
if (typeof window !== 'undefined') {
  const isDarkThemeMedia =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');
  defaultTheme = isDarkThemeMedia.matches ? 'dark' : 'light';
}
export const DEFAULT_THEME = defaultTheme;

const ThemeSwitcherContext = React.createContext(undefined);

export const ThemeSwitcherProvider = ({ children }) => {
  const [themeId, setThemeId] = useState(DEFAULT_THEME);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isDarkThemeMedia =
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');
      isDarkThemeMedia.onchange = e => {
        if (e.matches) {
          setThemeId('dark');
        } else {
          setThemeId('light');
        }
      };
    }
  }, []);

  return (
    <ThemeSwitcherContext.Provider value={{ themeId, setThemeId }}>
      {children(themeId)}
    </ThemeSwitcherContext.Provider>
  );
};

export const useThemeSwitcher = () => {
  const context = useContext(ThemeSwitcherContext);

  if (typeof context === 'undefined') {
    throw new Error('ThemeContext must be used within a ThemeContext provider');
  }

  return context;
};
